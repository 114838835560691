@import 'tippy.js/dist/tippy.css' layer(comp-layer);
@import 'tippy.js/themes/light.css' layer(comp-layer);
@import 'tippy.js/animations/scale.css' layer(comp-layer);

@layer comp-layer {
    .tippy-box[data-theme~='neptune'] {
        @apply bg-stone-950 text-slate-200;

        .tippy-arrow {
            @apply text-stone-950;
        }
    }
}
