@layer comp-layer {
    .field-list {
        .cdk-drag-preview {
            box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
            0 8px 10px 1px rgba(0, 0, 0, 0.14),
            0 3px 14px 2px rgba(0, 0, 0, 0.12);
        }

        .cdk-drag-placeholder {
            @apply box-border rounded-md border border-dashed border-primary opacity-30;
        }

        &.cdk-drop-list-dragging {
            @apply cursor-grabbing gap-4;

            .cdk-drag {
                transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
            }

            .add-item-in-between {
                display: none;
            }
        }
    }
}
