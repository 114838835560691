@layer comp-layer {
    .btn-light {
        @apply px-4 py-2 font-semibold text-sm bg-cyan-500 text-white rounded shadow-sm;
    }

    .btn-danger {
        @apply px-4 py-2 font-semibold text-sm bg-red-600 text-white rounded shadow-sm;
    }

    .btn-success {
        @apply px-4 py-2 font-semibold text-sm bg-green-600 text-white rounded shadow-sm;
    }

    .btn-primary {
        @apply px-4 py-2 font-semibold text-sm bg-blue-400 text-white rounded shadow-sm;
    }
}
