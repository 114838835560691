@layer comp-layer {
    .modal {
        @apply rounded-xl shadow-xl overflow-hidden text-slate-600;
    }

    .modal-title {
        @apply bg-stone-50;
    }

    .modal-body {
        @apply bg-stone-50;
    }

    .modal-footer {
        @apply bg-stone-50;
    }
}
