@use '@angular/material' as mat;
@import "@angular/cdk/overlay-prebuilt.css" layer(base-layer);

// Customize the entire app. Change :root to your selector if you want to scope the styles.
@layer base-layer {
    :root {
        @include mat.progress-spinner-overrides(
                (
                    active-indicator-color: theme('colors.primary.DEFAULT'),
                )
        );
    }
}
