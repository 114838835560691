@import "@yaireo/tagify/dist/tagify.css" layer(comp-layer);

@layer comp-layer {
    .custom-tagify {
        --tag-pad: 0 5px 0 5px;
        --tag-bg: theme('colors.blue.50');
        --tag-text-color: theme('colors.blue.600');
        --tag-hover: theme('colors.blue.100');

        .tagify__tag:hover:not([readonly]) div::before {
            --tag-bg-inset: 0;
        }
    }
}
