@layer comp-layer {
    .form-label {
        @apply block text-sm font-semibold text-stone-800 mb-1;
    }

    .form-input {
        @apply block w-full border border-stone-200/75 rounded-lg py-2 px-2 placeholder:text-sm placeholder:text-stone-400;

        &:hover,
        &:focus {
            @apply border-stone-800 outline-none;
        }

        &:disabled {
            @apply bg-stone-50 border-stone-200;
        }

        &.ng-invalid.ng-touched,
        .ng-submitted &.ng-invalid {
            @apply border border-red-500 relative;
        }
    }

    .form-error {
        @apply text-red-500 text-sm px-2 mt-1 hidden;

        .ng-submitted & {
            @apply block;
        }
    }
}
