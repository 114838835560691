@layer comp-layer {
    table.table-default {
        @apply w-full border-separate border-spacing-0;

        thead {
            th {
                @apply bg-white whitespace-nowrap py-3 px-5 font-medium text-sm text-stone-500 border-b-2 border-stone-200/75;
            }

            tr {
                //@apply ;
            }
        }

        tbody {
            tr:first-child {
                td {
                    @apply pt-6;
                }
            }

            td {
                @apply text-sm text-stone-800 py-3 px-5 border-b border-stone-200/75 min-h-[67px];
            }
        }
    }
}
