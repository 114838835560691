@use 'styles/tailwind-definition'; // must be first!
@use 'styles/scrollbar';
@use 'styles/tagify';
@use 'styles/tippy';
@use 'styles/buttons';
@use 'styles/forms';
@use 'styles/highlightjs';
@use 'styles/material';
@use 'styles/modal';
@use 'styles/tables';
@use 'styles/cdk-drag-drop';
@use 'styles/toasts';

@layer comp-layer {
    .ace_editor.ace-jsoneditor {
        min-height: 600px;
    }
}

body {
    overflow-y: hidden;
}
