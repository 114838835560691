.hot-toast-bar-base {
  align-items: var(--hot-toast-align-items, center);
  background-color: var(--hot-toast-bg, #fff);
  border-radius: var(--hot-toast-border-radius, 4px);
  box-shadow: var(--hot-toast-shadow, 0 3px 10px rgba(0, 0, 0, 0.1), 0 3px 3px rgba(0, 0, 0, 0.05));
  color: var(--hot-toast-color, #363636);
  display: var(--hot-toast-display, flex);
  line-height: var(--hot-toast-line, 1.3);
  margin: var(--hot-toast-margin, 16px);
  max-width: var(--hot-toast-max-width, 350px);
  padding: var(--hot-toast-padding, 8px 10px);
  pointer-events: var(--hot-toast-pointer-events, auto);
  width: var(--hot-toast-width, fit-content);
  will-change: var(--hot-toast-will-change, transform);

  &:hover,
  &:focus {
    animation-play-state: var(--hot-toast-animation-state, paused) !important;
  }

  @media (prefers-reduced-motion: reduce) {
    animation-duration: var(--hot-toast-reduced-motion-animation-duration, 10ms) !important;
  }
}

.hot-toast-message {
  color: var(--hot-toast-message-color, inherit);
  display: var(--hot-toast-message-display, flex);
  flex: var(--hot-toast-message-flex, 1);
  justify-content: var(--hot-toast-message-justify-content, center);
  margin: var(--hot-toast-message-margin, 4px 10px);
}

.hot-toast-bar-base-container {
  display: var(--hot-toast-container-display, flex);
  pointer-events: var(--hot-toast-container-pointer-events, none);
  position: var(--hot-toast-container-position, absolute);
  transition: var(--hot-toast-container-transition, transform 230ms cubic-bezier(0.21, 1.02, 0.73, 1));

  @media (prefers-reduced-motion: reduce) {
    transition-duration: var(--hot-toast-container-reduced-motion-transition-duration, 10ms) !important;
  }

  &.hot-toast-theme-snackbar {
    .hot-toast-bar-base {
      background: var(--hot-toast-snackbar-bg, #323232);
      box-shadow: var(
        --hot-toast-snackbar-shadow,
        0 3px 5px -1px rgba(0, 0, 0, 0.2),
        0 6px 10px 0 rgba(0, 0, 0, 0.14),
        0 1px 18px 0 rgba(0, 0, 0, 0.12)
      );
      color: var(--hot-toast-snackbar-color, rgba(255, 255, 255));
    }

    .hot-toast-close-btn {
      filter: var(--hot-toast-snackbar-close-btn-filter, invert(1) grayscale(100%) brightness(200%));
    }
  }
}

.hot-toast-close-btn {
  align-self: var(--hot-toast-close-btn-align-self, flex-start);
  background-color: var(--hot-toast-close-btn-background-color, transparent);
  background-image: var(
    --hot-toast-close-btn-background-image,
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e")
  );
  background-position: var(--hot-toast-close-btn-background-position, center);
  background-repeat: var(--hot-toast-close-btn-background-repeat, no-repeat);
  background-size: var(--hot-toast-close-btn-background-size, 0.75em);
  border: var(--hot-toast-close-btn-border, 0);
  border-radius: var(--hot-toast-close-btn-border-radius, 0.25rem);
  box-sizing: var(--hot-toast-close-btn-box-sizing, content-box);
  display: var(--hot-toast-close-btn-display, flex);
  height: var(--hot-toast-close-btn-height, 0.8em);
  margin-top: var(--hot-toast-close-btn-margin-top, 0.25em);
  opacity: var(--hot-toast-close-btn-opacity, 0.5);
  padding: var(--hot-toast-close-btn-padding, 0.25em);
  width: var(--hot-toast-close-btn-width, 0.8em);

  &:focus {
    box-shadow: var(--hot-toast-close-btn-box-shadow, 0 0 0 0.125rem rgb(13 110 253 / 25%));
    outline: var(--hot-toast-close-btn-outline, none);
  }

  &:hover,
  &:focus {
    opacity: var(--hot-toast-close-btn-opacity, 0.75);
  }
}

.hot-toast-icon {
  align-self: var(--hot-toast-icon-align-self, flex-start);
  padding-top: var(--hot-toast-icon-padding-top, 0.25em);
}

.hot-toast-bar-base-wrapper {
  pointer-events: all;
}

@keyframes hotToastEnterAnimationNegative {
  0% {
    opacity: 0.5;
    transform: translate3d(0, -80px, 0) scale(0.6);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1);
  }
}

@keyframes hotToastEnterAnimationPositive {
  0% {
    opacity: 0.5;
    transform: translate3d(0, 80px, 0) scale(0.6);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1);
  }
}

@keyframes hotToastExitAnimationPositive {
  0% {
    opacity: 1;
    transform: translate3d(0, 0, -1px) scale(1);
  }

  100% {
    opacity: 0;
    transform: translate3d(0, var(--hot-toast-exit-positive-y, 130px), -1px) scale(0.5);
  }
}

@keyframes hotToastExitAnimationNegative {
  0% {
    opacity: 1;
    transform: translate3d(0, 0, -1px) scale(1);
  }

  100% {
    opacity: 0;
    transform: translate3d(0, var(--hot-toast-exit-negative-y, -130px), -1px) scale(0.5);
  }
}

@keyframes hotToastEnterSoftAnimationNegative {
  0% {
    opacity: 0.5;
    transform: translate3d(0, -14px, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes hotToastEnterSoftAnimationPositive {
  0% {
    opacity: 0.5;
    transform: translate3d(0, 14px, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes hotToastExitSoftAnimationPositive {
  0% {
    opacity: 1;
    transform: translate3d(0, 0, -1px);
  }

  100% {
    opacity: 0;
    transform: translate3d(0, 14px, -1px);
  }
}

@keyframes hotToastExitSoftAnimationNegative {
  0% {
    opacity: 1;
    transform: translate3d(0, 0, -1px);
  }

  100% {
    opacity: 0;
    transform: translate3d(0, -14px, -1px);
  }
}
