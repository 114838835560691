@import 'perfect-scrollbar/css/perfect-scrollbar.css' layer(comp-layer);

@layer base-layer {
    /* Für WebKit-Browser (Chrome, Safari) */
    ::-webkit-scrollbar {
        width: 12px; /* Breite der Scrollbar */
    }

    ::-webkit-scrollbar-track {
        background: transparent; /* Kein Hintergrund */
    }

    ::-webkit-scrollbar-thumb {
        background-color: rgb(236 234 233); /* Farbe des Scrollbalkens */
        border-radius: 10px; /* Abgerundete Ecken */
        border: 3px solid transparent; /* Abstand zwischen Scrollbalken und Track */
    }

    /* Für Firefox */
    * {
        scrollbar-width: thin;
        scrollbar-color: rgb(236 234 233) transparent;
    }
}
